// Body
$body-bg: #fff;
$body-color: #0b0b0b;

// Typography
$font-family-sans-serif: Arial,
Helvetica,
sans-serif;
$font-size-base: 1rem;
$font-weight-base: normal;
$line-height-base: 1.5;
