// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fontawesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";


:root {
    --navbar-bg-color: #d2bb76;
    --header-color: #CC3333;
    --link-color: #d2bb76;
    --link-hover-color: #23527c;
    --body-color: #0b0b0b;
    --navbar-links-color: #FFF;
    --navbar-links-color-active: #ba6204;
    --primary-bg-color: #d2bb76;
    --primary-color: #FFF;
    --primary-hover-bg-color: #ba6204;
    --primary-hover-color: #FFF;
    --bg-color: #f5f5f5;

}

.navbar {
    background-color: var(--navbar-bg-color);
}

a:link,
a:visited {
    color: var(--link-color);
    text-decoration: none;
}

a:hover,
a:active {
    color: var(--link-hover-color);
    text-decoration: underline;
}


.btn-primary,
.btn-primary:link,
.btn-primary:visited {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-primary:hover,
.btn-primary:active {
    color: var(--primary-hover-color);
    background-color: var(--primary-hover-bg-color);
    border-color: var(--primary-hover-bg-color);
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #ddd;
    border-color: #ddd;
    color: #fff;
}

.pagination {
    .page-item .page-link {
        color: var(--primary-bg-color);
    }

    .page-item.active .page-link {
        color: #fff;
        background-color: var(--primary-bg-color);
        border-color: var(--primary-bg-color);
    }
}

.navbar-light .navbar-nav .nav-link {
    color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:active {
    color: var(--navbar-links-color-active);
    text-decoration: none;
}

.person-detail {
    .row {
        //border-top: 1px solid #ddd;
    }

    .bordercell {
        border-top: 1px solid #ddd;
    }

    .bordercell-bottom {
        border-bottom: 1px solid #ddd;
    }

    .row:last-child {
        border-bottom: 1px solid #ddd;
    }

    .archive_relation_detail .row,
    .edition_detail .row {
        border-bottom: none;
    }

    .row>* {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .edition_detail .row>*,
    .archive_relation_detail .row>* {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .header-row {
        background-color: var(--bg-color);
        font-weight: bold;
        border-top: 1px solid #ddd;
    }

    .labelcell {
        font-weight: bold;
    }
}

.footnote {
    text-decoration: none;
    cursor: pointer;
    padding-left: 2px;
}

.footnote:hover,
.footnote:active {
    text-decoration: none;
}

.custom-popover {
    --bs-popover-bg: var(--primary-bg-color);
}

.form-group.required label:after {
    content: " *";
    color: red;
}

#person-form .help-block {
    color: red;
}
